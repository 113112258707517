import { render, staticRenderFns } from "./base-input-text.vue?vue&type=template&id=32985222&"
import script from "./base-input-text.vue?vue&type=script&lang=js&"
export * from "./base-input-text.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CFormLabel: require('@chakra-ui/vue').CFormLabel, CInputLeftAddon: require('@chakra-ui/vue').CInputLeftAddon, CInputLeftElement: require('@chakra-ui/vue').CInputLeftElement, CInput: require('@chakra-ui/vue').CInput, CText: require('@chakra-ui/vue').CText, CInputRightElement: require('@chakra-ui/vue').CInputRightElement, CInputRightAddon: require('@chakra-ui/vue').CInputRightAddon, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CFormControl: require('@chakra-ui/vue').CFormControl})
